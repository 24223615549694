<script setup lang="ts">
import { RankListApi } from '~/net/apis/ranking_list_api'
import { Tab_Filter_Type } from '~/enums/tab_filter-type'

interface IVideoType {
  tabId: Tab_Filter_Type
  title: string
}
const active = ref(Tab_Filter_Type.newest)
const mmActive = ref(1)
const videoList = ref<any[]>([])
const router = useRouter()
const { y } = useWindowScroll()
const onClickLeft = () => history.back()
const onClickHandle = (value: number) => (mmActive.value = value)
const videoTypeList: IVideoType[] = [
  {
    tabId: Tab_Filter_Type.newest,
    title: '观看榜'
  },
  {
    tabId: Tab_Filter_Type.watch,
    title: '收藏榜'
  },
  {
    tabId: Tab_Filter_Type.collect,
    title: '付费榜'
  }
]
</script>

<template>
  <div class="rank-warpper" :class="`active_${active}`" min-h-screen>
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div flex gap-50px>
          <button :class="{ mmActive: mmActive === 1 }" text-xs text-white @click="onClickHandle(1)">周榜</button>
          <button :class="{ mmActive: mmActive === 2 }" text-xs text-white @click="onClickHandle(2)">月榜</button>
        </div>
      </template>
    </van-nav-bar>
    <div mt-22px flex flex-col items-center justify-center>
      <img w-272px src="/decoration_text@2x.png" />
      <p mt-6px text-4 op60 ct-ffffff>小编吐血立荐·您的观影指南</p>
    </div>
    <van-tabs v-model:active="active">
      <van-tab v-for="item in videoTypeList" :key="item.tabId" :name="item.tabId" :title="item.title">
        <div class="sroll-view h-68vh flex-1 overflow-auto">
          <PullRefreshListBottom
          wrap-class="w-full grid grid-cols-1 gap2 px-15px"
          :disabled-list="true"
          :request-api="RankListApi.query"
          :offset-list="20"
          head-height="32vh"
          :data-call-back="(Response) => {
            const data = Response.record;
            Response.record = data.map((item:RankVideo, index:number) => { return { ...item, sort: (index + 1) } })
            return Response;
          }"
          :request-params="{
            pageNum: 1,
            pageSize: 20,
            rankType: mmActive,
            type: active
          }"
        >
          <template #="{ data }">
            <div v-for="(item, index) in data" :key="index">
              <MediaRankingCard :item="item as RankVideo" />
            </div>
          </template>
          <template #bottom>
            <BaseButton
              text="查看完整榜单"
              mb-4 ml-15px mt-5 w-345px text-3.75
              @click="$router.push(`/ranking-list/all/${mmActive}/${active}`)"
            />
          </template>
        </PullRefreshListBottom>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar) {
  background: transparent;
}
:deep(.van-nav-bar__content) {
  background: transparent;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
:deep(.van-tabs) {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 2px solid #645647;
  border-bottom: none;
  flex: 1;
  overflow: hidden;
  margin-top: 28px;
}
:deep(.van-tabs__nav) {
  background: transparent;
  color: #e8e8e8;
  font-size: 14px;
}
:deep(.van-tab--active) {
  position: relative;
  color: #ffffff;
  font-size: 18px;
}
:deep(.van-tabs__line) {
  width: 0;
}
.rank-warpper {
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &.active_1 {
    background-image: url('~/assets/image/ranking_list/watch_rank@2x.png');
    background-color: #070E20;
    .van-tabs{
      background-color: #070E20;
    }
  }
  &.active_2 {
    background-image: url('~/assets/image/ranking_list/favorite_rank@2x.png');
    background-color: #2E1F17;
    .van-tabs{
      background-color: #2E1F17;
    }
  }
  &.active_3 {
    background-image: url('~/assets/image/ranking_list/pay_rank@2x.png');
    background-color: #393536;
    .van-tabs{
      background-color: #393536;
    }
  }
  &.active_1,
  &.active_2,
  &.active_3 {
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
  }
  :deep(.van-tab--active:after){
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 9px;
    margin: 0 auto;
    width: 20px;
    height: 2px;
    background: linear-gradient(90deg, #fd9403 0%, #fd5b03 100%);
  }
}

.mmActive {
  color: #ffce97;
  font-size: 18px;
}
:deep(.sroll-view::-webkit-scrollbar){
  width: 0.01rem;
}
:deep(.van-pull-refresh::-webkit-scrollbar){
  width: 0.03rem;
}
.btn {
  background: linear-gradient(0deg, #373737, #373737), linear-gradient(130.94deg, #fd9403 16.87%, #fd5b03 89.42%);
}
</style>
